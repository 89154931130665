@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;700&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Cabin", sans-serif !important;
  overflow-x: hidden;
  line-height: normal !important;
  font-style: normal !important;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

.pos-relative {
  position: relative;
}

.cursor-pointer {
  cursor: pointer!important;
}

*#style-7::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

*::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(44, 142, 198, 1);
}

.nav-pills::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 1);
  background-color: #f5f5f5;
  border-radius: 12px;
}

.nav-pills::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
  width: 1px;
  height: 3px;
  background-color: #f5f5f5;
  border-radius: 6px;
}

.nav-pills::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(44, 142, 198, 1);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.btn {
  box-shadow: none !important;
}
