:root {
  --primary_color: #2c8ec6;
  --sub_text: #9e9e9e;
  --input_bg: #f0f3f9;
  --black: #000000;
  --white: #ffffff;
  --table_bg: #f1f1f1;
  --border_color: #cbcbcb;
  --listContainer: #eff5ff;
  --green: #29b848;
  --labelBg: #d6eedb;
  --graph-bg: #F4FBFF;
  --dark_text: #212529;
}

.container-padding {
  padding: 1rem 4rem;
}

.btn {
  border-radius: 50px;
  border: 1px solid transparent !important;
}

.btn-lg {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.btn-primary:hover,
.btn-primary:active {
  background-color: var(--white) !important;
  color: var(--primary_color) !important;
  border: 1px solid var(--primary_color) !important;
}

.z-joule-container img {
  height: 100%;
  width: 7rem;
}

.btn-primary {
  color: var(--white);
  --bs-btn-bg: var(--primary_color);
  
  filter: drop-shadow(13px 13px 23.9px rgba(9, 67, 159, 0.3));
  width: fit-content;
}

.btn-primary:disabled {
  opacity: 50% !important;
  background-color: var(--primary_color) !important;
  pointer-events: unset;
  color: var(--white) !important;
}

.btn-secondary {
  color: var(--primary_color);
  --bs-btn-bg: var(--white);
  border: 1px solid var(--primary_color) !important;
}

.btn-secondary:hover,
.btn-secondary:active {
  background-color: var(--primary_color) !important;
  color: var(--white) !important;
  border: 1px solid var(--primary_color) !important;
}

.btn-secondary.info {
  padding: 0.4em;
  width: fit-content;
}

.btn-secondary.info:active,
.btn-secondary.info:hover {
  background-color: var(--white) !important;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-28 {
  font-size: 28px;
}
.text-dark {
  color: var(--dark_text);
}
.fill-width {
  width: -webkit-fill-available;
}
.w-fit-content {
  width: fit-content;
}
.assign-route {
  display: flex;
  align-items: center;
  gap: 0.3em;
  border: none;
}

.assign-route:hover,
.assign-route:focus,
.assign-route:active {
  background-color: var(--listContainer) !important;
  color: var(--primary_color) !important;
}

/* login css */
.login-bg {
  width: 60vw;
  position: absolute;
  right: 0;
  z-index: 1;
  top: 0;
}
.login-bg img {
  width: 100%;
  object-fit: cover;
  height: 100vh;
}
.login-container {
  position: relative;
  background-color: var(--white);
  width: 100%;
}

.login-container.splash-screen {
  padding-bottom: 3em;
}
.login_left_container {
  z-index: 99;
}

.login_left_container h3 {
  color: var(--black);
  font-size: 2vw;
  font-weight: 500;
}

.login_left_container p {
  color: var(--sub_text);
  font-size: 1vw;
  font-weight: 400;
  line-height: 175.567%;
}

.login_left_container .login-image img {
  width: 460.622px;
  height: 100%;
}

.login_form_container {
  position: relative;
  display: flex;
  padding-left: 0;
  align-items: center;
  height: 44vh;
  margin-left: 3vw;
}

.login-detail {
  width: 25vw;
  height: 45vh;
}

.mt-2vh {
  margin-top: 2vh !important;
}

.big-text-vw {
  font-size: 3vw !important;
  color: var(--primary_color);
}
.p-2vh2vw {
  padding: 2vh 2vw;
}

.gap-5vh {
  gap: 5vh !important;
}
.gap-4vh {
  gap: 4vh !important;
}
.gap-3vh {
  gap: 3vh !important;
}
.mb-2vh{
  margin-bottom: 2vh !important;
}

.fts-1vw {
  font-size: 1vw !important;
}
.mail-input input,
.pass-input input {
  position: relative;
  width: 100%;
  background-color: var(--input_bg);
  border: 1px solid transparent;
  padding: 1.5vh 2vw;
  border-radius: 10px;
}
.custom-btn{
  padding: 1.5vh 2vw;
  border-radius: 10px;
}

.mail-input.parameter input {
  padding: 0.6em 1.33em;
  border: 0.094em solid #ADADAD;
  background: #FFF;
  border-radius: 1.375em;
}

.base-price {
  padding: 0.5em 1.33em;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-container-no-flex {
  position: relative;
  align-items: center;
}

.dollar-symbol {
  position: absolute;
  left: 10px;
  font-size: 1rem;
  color: #333;
  z-index: 1;
  font-weight: bold;
}

.dollar-margin {
  margin-top: 0.70rem;
}

.dollar-input input {
  padding-left: 2.5rem;
  z-index: 0;
}

.mail-input.parameter img {
  top: 50%;
  left: 90%;
  transform: translate(-95%, -50%);
}

.mail-input input:focus,
.common-input input:focus,
.pass-input input:focus,
.edit-detail-container input {
  outline: none;
  box-shadow: none;
}

.mail-input input::placeholder,
.pass-input input::placeholder {
  color: var(--sub_text);
  font-size: 0.8em;
  font-weight: 500;
}

.error-input {
  border: 1px solid !important;
  border-color: rgb(255, 128, 128) !important;
}

.visibility-icon svg {
  position: absolute;
  top: 50%;
  right: 4%;
  z-index: 9;
  transform: translate(-4%, -50%);
  cursor: pointer;
  fill: var(--sub_text);
  font-size: 1.2em;
}

.mail-input img,
.pass-input img {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 4%;
  transform: translate(-4%, -50%);
}

.forgot-password a {
  color: var(--primary_color);
  font-weight: 400;
  text-decoration-line: underline !important;
}

.sign-up {
  color: var(--black);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-top: 2vh;
}

.sign-up a {
  color: var(--primary_color);
  font-weight: 700;
  text-decoration-line: underline !important;
}

.forgot-password-container h2 {
  color: var(--primary_color);
  text-align: center;
  font-size: 2.5em;
  font-weight: 700;
}

.forgot-password-container p {
  color: var(--sub_text);
  font-size: 0.8em;
  font-weight: 400;
  line-height: 175.567%;
}

.spinner-border {
  height: 1em;
  width: 1em;
  border-width: 0.15em;
  margin-left: 0.5rem;
}

.setting-buttons {
  position: absolute;
  z-index: 9;
}

.setting-buttons.route-editor {
  right: 0.4%;
  top: 10%;
  transform: translate(-0.4%, -10%);
}

.setting-buttons.vessel-editor {
  right: 0.4%;
  top: 17%;
  transform: translate(-0.4%, -17%);
}

.setting-buttons.fuel-forcast {
  right: 0.4%;
  top: 24%;
  transform: translate(-0.4%, -24%);
}

.setting-buttons.legislative-scenarios {
  right: 0.4%;
  top: 31%;
  transform: translate(-0.4%, -31%);
}

.setting-buttons.global-parameter {
  right: 0.4%;
  top: 38%;
  transform: translate(-0.4%, -38%);
}

.setting-btn {
  padding: 0.5rem 2em;
}

.setting-btn:hover,
.setting-btn:active,
.setting-btn:focus {
  background-color: var(--primary_color) !important;
  color: var(--white) !important;
}

.btn-primary>.setting-btn-content {
  opacity: 0;
  transition: opacity 0.5s ease-in-out, max-width 0.5s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  max-width: 0;
}

.btn-primary:hover>.setting-btn-content {
  opacity: 1;
  width: auto;
  max-width: 200px;
  transition: opacity 0.5s ease-out, max-width 1s ease-out;
}

.btn.save {
  padding: 20px 63px 21px 63px;
}

.cancle-btn:hover,
.cancle-btn:active,
.cancle-btn:focus {
  background-color: var(--white) !important;
}

/* navbar */

.navbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  background: var(--z-joule-com-nero-nero, linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF);
}

.navbar .navbar-brand {
  height: 55px;
  width: 145px;
}

.navbar .navbar-brand img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.navbar-nav {
  gap: 0.3em;
}

.nav-new-container {
  display: flex;
  padding: 0 4rem;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.12);
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.faq-button {
  display: flex;
  width: 5.5rem;
  padding: .2rem 3rem;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  border-radius: 100px;
  border: 1px solid #2C8EC6;
  background: #FFF;
}
.z-joule-container {
  display: flex;
  align-items: center;
  gap: 0.3em
}

.z-joule-container p {
  color: var(--sub_text);
  font-size: 0.8em;
  margin-bottom: 0;
}

.info-container {
  position: absolute;
  top: 7em;
  right: 0em;
  background-color: var(--white);
  border-radius: 24px;
  box-shadow: 0px 0px 21.8px 0px rgba(0, 0, 0, 0.1);
  min-height: 10em;
  width: 25em;
  overflow: auto;
  padding: 1.5em 1em;
}

.info-container h3 {
  color: var(--primary_color);
  font-size: 1em;
}

.info-container li {
  margin-top: 1em;
}

.info-container li::marker {
  color: var(--primary_color);
}
/* main container */

.main-container {
  padding: 3em 0;
  height: 100%;
}

.login_left_container .login-image {
  width: 500px;
  height: 600px;
  display: flex;
  align-items: self-start;
}

.login_left_container .login-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.main-right-container h1 {
  color: var(--black);
  font-size: 2.5em;
  font-weight: 400;
}

.main-right-container p {
  color: var(--sub_text);
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 2rem;
}

/* table container */
.vessel-table {
  position: relative;
  background-color: #FFF;
}


.table-header,
.span-lg {
  color: #2C8EC6;
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2em;
}

.table-header .action-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.5em;
  flex-direction: row;
}

.table-header .action-container .btn-table-header .actions {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.btn-table-header {
  display: flex;
  align-items: center;
  gap: 0.6em;
  justify-content: flex-end;
  padding: 0.4em 0.5em;
  border: 1px solid var(--primary_color);
  border-radius: 50px;
}

.btn.fleet {
  font-size: 1em;
}

.btn-table-header p {
  font-size: 0.8em;
}

.table-header h2 {
  color: var(--primary_color);
  font-size: 2.3em;
  font-weight: 700;
}

.table-shadow-container {
  height: 93vh;
  overflow: hidden;
  margin-top: 0.5em;
  border-end-end-radius: 5px;
  border-end-start-radius: 5px;
}

.table-detail-inner-container {
  height: 100%;
  overflow-x: auto;
  margin-top: 0.5em;
  padding-right: 18px;
  border-radius: 16px;
  position: relative;
  padding-bottom: 1em;
}

/* new */

.table-content-container .vessel-detail-container {
  background: #EFF5FF;
  align-items: flex-start;
  gap: 24px;
  padding: 0.75em;
  border-radius: 8px;
  width: 46%;
  text-align: end;
}

.table-content-container .vessel-detail-container .header {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 0.5em;
}
.table-content-container .vessel-detail-container .header.route-title h3 {
  font-size: 18px;
}

.table-content-container .vessel-detail-container .header h3 {
  color: var(--primary_color);
  text-transform: capitalize;
  font-size: 1.5em;
  font-weight: 500;
}

.table-content-container .vessel-detail-container .header h3 span {
  color: var(--black);
  font-size: 1em;
}


.table-content-container .vessel-detail-container .key {
  color: var(--primary_color);
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 0.4em;
}

.key-header{
  color: #484848;
  font-size: 1.125em;
  font-weight: 600;
  margin-bottom: 0.4em;
  font-family: Cabin, sans-serif;
  word-wrap: break-word;
  max-width: 100%;
}

.table-content-container .vessel-detail-container .value {
  color: var(--black);
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 0.4em;
  text-align: left;
}

.table-content-container .vessel-detail-container .aux-list {
  padding-left: 0;
}

.table-content-container.vessel-detail-container .tank-detail {
  list-style: none;
  padding: 0;
}

/* modal */

.modal-backdrop {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
  opacity: 1 !important;
}

.modal-content {
  border-radius: 36px;
  background: var(--white);
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.1);
  border: none;
  padding: 1rem 1rem;
  position: relative;
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-header .btn-primary.close {
  position: absolute;
  right: -1%;
  top: -2%;
  padding: 0.5em;
  transform: translate(2%, 1%);
  max-width: 20em;
}

.modal-header .btn-primary.close:hover,
.modal-header .btn-primary.close:focus {
  background-color: var(--primary_color) !important;
  color: var(--white) !important;
}

.close-icon {
  height: 1.4em;
  width: auto;
}

.modal-title {
  color: var(--primary_color);
  font-size: 2.5em;
  font-weight: 700;
}

/* global parameter modal */

.global-parameter-container {
  margin: 1rem 0;
}

.input-label {
  color: var(--black);
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 4px;
  text-align: start;
}

.revenue-modal-title{
  color:#2C8EC6
}

/* regulatory context modal */

.chart-container {
  margin-top: 1em;
}

.chart-container p {
  color: var(--black);
  margin-bottom: 10px;
  font-size: 1em;
  font-weight: 500;
}

.chart-bg {
  background-color: var(--graph-bg);
  padding: 1rem;
  width: 100%;
  height: 500px;
  position: relative;
}

/* assign route modal */

.map-conatiner {
  height: 100%;
  overflow: hidden;
  border-radius: 36px;
  width: 100%;
}

.loader-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.map-css {
  width: 100%;
  height: 100%;
}

.map-css>div:first-child {
  zoom: 70% !important;
}

.route-main-container {
  padding: 0.5rem;
  height: 100%;
}

.route-container {
  background-color: var(--input_bg);
  border-radius: 26px;
  padding: 10px;
}

.scroll-container {
  overflow-y: auto;
  height: 35em;
  padding-right: 0.5rem;
}

.route-detail-container {
  border-right: 1px solid var(--border_color);
}

.route-detail-container.port {
  border-top: 1px solid var(--border_color);
  border-right: 0;
  text-align: center;
}

.route-detail-container .key {
  color: var(--primary_color);
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 0.4em;
}

.route-detail-container .value {
  color: var(--black);
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 0.4em;
}

.route-detail {
  background-color: var(--white);
  border-radius: 22px;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 0.5rem;
  border: 3px solid transparent;
}

.active-route {
  border: 3px solid var(--primary_color);
}

.route-detail .title {
  color: var(--primary_color);
  font-size: 1.2em;
  font-weight: 500;
}

.speed-title {
  color: var(--primary_color);
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.speed-title img {
  height: 1.5em;
  width: 1.2em;
}

.speed-info-container {
  position: absolute;
  width: auto;
  top: 0%;
  left: 35%;
  transform: translate(-35%, -0%);
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 0px 0px 21.8px 0px rgba(0, 0, 0, 0.1);
  padding: 1.5em;
  z-index: 9;
}

.speed-info-container .close {
  position: absolute;
  right: 0%;
  top: -10%;
  padding: 0.2em;
  transform: translate(-0%, -10%);
}

.speed-info-container .close img {
  height: 1.3em;
  width: 1.5em;
}

.speed-min-max p {
  color: var(--black);
  text-align: right;
  margin-bottom: 0;
  font-size: 1em;
  font-weight: 400;
}

.speed-label-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.speed-label-box p {
  color: var(--primary_color);
  text-align: right;
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: 0px;
  margin-top: 20px;
}

.text-error {
  color: var(--primary_color);
  font-size: 20px;
  font-weight: 700;
}

/* edit vessel control modal */

.vessel-detail .title {
  color: var(--primary_color);
  font-size: 1.2em;
  font-weight: 700;
}

.select-box-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%
}

.select-box-container-v2 {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.select-box {
  background-color: #FFF;
  border: .094em solid #adadad;
  border-radius: 1.375em;
  padding: 10px 12px;
  position: relative;
}

.dropdown-add-btn{
  cursor: pointer;
}
.select-box-v2 {
  padding: 0.6em 1.13em;
  align-items: center;
  border-radius: 1.375em;
  border: 0.094em solid #ADADAD;
  background: #FFF;
}

.small-select-box {
  width: 12.62em;
  padding: 0.59em 1.12em;
  align-items: center;
  border-radius: 1.375em;
  border: 0.094em solid #ADADAD;
  background: #FFF;
}

.select-year {
  border: 1px solid var(--primary_color);
  padding: 0.8em 0.8em;
  font-size: 0.8em;
}

.selectyear-container {
  width: 11em;
}

.select-icon {
  position: absolute;
  right: 4%;
  top: 19%;
  transform: translate(-25%, -1%);
}

.select-year .selectBox__value {
  color: var(--primary_color);
  font-size: 1em;
}

.selectBox__value {
  color: var(--black);
  font-size: 0.8em;
  font-weight: 500;
}

.dropdown-menu-list {
  transition: all 0.5s ease-in-out;
  display: block;
  width: 100%;
  z-index: 9;
  border-radius: 24px;
  background: var(--white);
  box-shadow: 0px 0px 21.8px 0px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-top: 10px;
  position: absolute;
  left: 0;
  top: calc(100% + 0px);
}

.dropdown-container {
  max-height: 250px;
  overflow-y: auto;
  color: var(--bs-dropdown-link-hover-color);

}

.init.dropdown-item {
  color: var(--sub_text);

}

.init.dropdown-item:hover {
  background-color: var(--bs-dropdown-link-hover-bg);
  color: var(--sub_text);

}

.dropdown-item:last-child {
  border: none;
}

.dropdown-item {
  color: var(--black);
  font-size: 0.8em;
  font-weight: 500;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 1px solid var(--input_bg);

}

.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.dropdown-item.active {
  color: var(--primary_color) !important;
  font-weight: 600;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--sub_text);
}

.btn-secondary.delete {
  background-color: var(--white) !important;
  border: none !important;
  box-shadow: 13px 13px 25px 0px rgba(0, 0, 0, 0.1) !important;
  padding: 0.6em;
}

.btn-primary.add {
  padding: 0.438em;
  background-color: var(--primary_color);
  filter: unset;
}
.btn-primary.add.back {
  background-color: var(--primary_color);
}

.btn-primary.add.back:hover {
  background-color: var(--primary_color) !important;
  color: var(--white) !important;
  border-color: transparent !important;
}

.btn-primary.add.secondary {
  background-color: #212529;
}

.btn-primary.add:hover {
  background-color: #212529 !important;
  color: var(--white) !important;
  border-color: transparent !important;
}

.fuel-type-input {
  position: absolute;
  top: 30%;
  right: 5%;
  transform: translate(-30%, -5%);
  color: var(--primary_color);
  font-size: 0.8em;
  font-weight: 700;
}

.faq-container {
  padding: 1em 7em;
}

.faq-container h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: var(--black);
  text-align: center;
  font-size: 2.5em;
  font-weight: 700;
}

.question-container {
  padding: 0.5em 2em;
}

.question-container ul {
  margin: 0;
  list-style: none;
  background-color: var(--table_bg);
  padding: 1em;
  border-radius: 16px;
}

.question p {
  color: var(--black);
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 0;
}
.answer {
  margin-top: 0.5em;
  color: var(--sub_text);
  font-size: 1em;
  font-weight: 400;
}

ul {
  list-style-type: none;
}

li[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}

li {
  cursor: pointer;
}

.sb-avatar__text {
  width: 3.75rem !important;
  height: 3.75rem !important;
  border-radius: 50% !important;
  border: 2px solid var(--z-joule-com-nero-nero, #FFF) !important;
  background: var(--sfsf-z-joule-com-lochmara, #0284C4) !important;
  font-family: "Cabin", sans-serif !important;
}

.sb-avatar {
  width: 100% !important;
  height: 100% !important;
}

.answer p {
  margin-bottom: 0;
}

.answer ul {
  list-style: disc;
}

.answer ul li {
  margin-bottom: 0.4em;
}

.answer a {
  color: var(--primary_color);
}

/* fleet wide */

.fleet-wide-container {
  padding: 2em 4rem;
}

.fleet-wide-header {
  display: flex;
  justify-content: flex-start;
  gap: 0.5em;
}

.nav-pills .nav-link.active,
.nav-pills .nav-item.show .nav-link {
  background-color: var(--primary_color);
  border-radius: 50px;
  color: var(--white);
  border: 1px solid var(--primary_color);
}

.nav-pills .nav-link,
.nav-pills .nav-item.show .nav-link {
  background-color: var(--white);
  border-radius: 50px;
  border: 1px solid var(--primary_color);
  color: var(--primary_color);
  width: 10em;
  padding: 0.8em 0.5em;
  margin: 0 0.4em 0.5em;
}

.nav-pills:first-child .nav-link {
  margin-left: 0;
}

.nav-pills .back-btn-list {
  margin-right: 0.4em;
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: none !important;
}

.rounded-16 {
  border-radius: 16px;
}

.tab-content-title {
  color: var(--black);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.tab-content-title.fuel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-purchase {
  color: var(--black);
  font-size: 0.7em;
  font-weight: 500;
  margin-bottom: 0.3em;
}


.back-btn {
  display: none;
}

.fleet-chart-container {
  background-color: var(--input_bg);
  padding: 1em;
  border-radius: 0px 20px 0px 0px;
  width: 100%;
  height: 850px;
}

.w-10rem{
  width:10rem !important
}

.mr-sm{
  margin-right:0.5rem !important
}

.fleet-chart-container-inner{
  border-radius: 20px;
  width: 100%;
  height: 650px;
  padding: .2rem 0 !important;
}

.ets-title {
  color: var(--primary_color);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.edit-revenue-container {
  background: var(--white);
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  right: 3rem;
  width: 40%;
  max-height: 27em;
  height: auto;
  overflow: auto;
  z-index: 9;
  display: flex;
  justify-content: center;
  left: inherit;
}

.assign-revenue-container {
  padding: 0.5em;
  background: var(--white);
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  right: 3rem;
  width: 12rem;
  max-height: 27em;
  height: auto;
  overflow: auto;
  z-index: 9;
  display: flex;
  justify-content: center;
  left: inherit;
}

.edit-revenue {
  padding: 1em;
}

.edit-revenue-action {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  margin-top: 0.4em;
}

.edit-revenue .save {
  background-color: green !important;
  border: none !important;
  padding: 0;
  border-radius: 50px;
}

.edit-revenue .save:disabled {
  opacity: 0.6;
}
.edit-revenue .close {
  background-color: #de6161 !important;
  border: none !important;
  padding: 0.3em;
  border-radius: 50px;
}

.edit-revenue .save img {
  height: 2em;
  width: auto;
}

.edit-revenue input {
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #ADADAD;
  padding: 0.5em;
  padding-left: 0.75em;
  border-radius: 50px;
  font-size: 1em;
}

.edit-revenue input::placeholder {
  font-size: 0.9em;
}

.edit-revenue input:focus {
  outline: none;
  box-shadow: none;
}

.table-recommendation {
  border-radius: 16px !important;
  width: 100%;
  background-color: var(--white) !important;
}

.table-recommendation th,
.table-recommendation td {
  padding: 1em;
}

.table-recommendation thead tr th {
  padding: 0.5em;
  font-size: 1.1em;
  color: var(--primary_color);
  text-align: center;
}

.table-recommendation tbody tr td {
  font-size: 1em;
  color: var(--black);
  text-align: center;
  position: relative;
}

.show-more {
  color: var(--primary_color);
  margin-bottom: 0;
  margin-top: 0.6em;
  font-weight: 700;
}

.cii-data {
  border-radius: 50px !important;
  border: 2px solid var(--black);
  width: auto;
  height: auto;
}

.cii-designation {
  margin-bottom: 0;
  color: var(--black);
  font-weight: bold;
  padding: 0.7em 1em;
}

.fuel-container {
  white-space: nowrap;
  height: 2em;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.speed-container {
  white-space: nowrap;
  background-color: #d3e0ed;
  width: 60%;
  height: 2em;
  position: relative;
  border: 2px solid var(--black);
}

.speed-main-container {
  background-color: #d3e0ed;
  height: 2em;
  border: 2px solid var(--black);
}

.optimal-speed {
  position: absolute;
  top: 0;
  border-radius: 50px;
  height: 85%;
  width: 1.7em;
  margin-top: 0.2em;
  cursor: pointer;
}

.slide-animation {
  animation: slideLeftToRight 1s forwards;
  /* 1s is the duration of the animation */
}

@keyframes slideLeftToRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.slide-animation-new {
  animation: slideLeftToRightnew 1s forwards;
  /* 1s is the duration of the animation */
}

@keyframes slideLeftToRightnew {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.targated-speed {
  position: absolute;
  top: -25%;
  height: 2.7em;
  width: 0.2em;
  cursor: pointer;
  background-color: var(--primary_color);
}

.fuel-bg {
  display: inline-block;
  height: 100%;
  border-radius: 1px;
  cursor: pointer;
}

.speed-bg {
  height: 100%;
  width: 1.8em;
  border-radius: 50px;
  border: 1px solid var(--black);
  cursor: pointer;
}

.target-speed {
  position: absolute;
  height: 3.5em;
  width: 0.2em;
  background-color: var(--primary_color);
  top: -50%;
  left: 10%;
  z-index: 0;
}

.min-speed {
  position: absolute;
  height: 86%;
  width: 0.2em;
  background-color: var(--sub_text);
  cursor: pointer;
  z-index: 9;
}

.max-speed {
  position: absolute;
  height: 100%;
  width: 0.2em;
  background-color: var(--sub_text);
  cursor: pointer;
  top: 0;
}

.eufm-chart {
  height: 5em;
  width: 100%;
  border-right: 1px dashed var(--primary_color);
}

.draw-box {
  background-color: #ff0000;
  border: 2px solid var(--black);
  height: 2em;
  margin-top: 1.5em;
}
.draw-box-2 {
  background-color: #8ed973;
  border: 2px solid var(--black);
  height: 2em;
  margin-top: 1.5em;
}

.table-content-container {
  width: 100%;
  display: flex;
  align-items: stretch;
  background-color: var(--white);
  padding: 2em;
  border-radius: 1em;
  gap: 1em;
  margin-top: 1em;
  border: 1px solid var(--sfsf-z-joule-com-silver-chalice, #9E9E9E);
  background: #FFF;
}

.table-content-container.selected {
  background-color: var(--listContainer);
}

.table-content-container .vessel-detail-container {
  border: 1px solid var(--listContainer);
}

.table-content-container.selected .vessel-detail-container {
  background-color: var(--white);
  border: 1px solid var(--primary_color);
}

.table-content-container figure {
  position: relative;
  display: flex;
  width: 15em;
  height: 15em;
  margin: 0;
}

.table-content-container figure img {
  height: 100%;
  width: 100%;
  box-shadow: 0px 7px 11.4px 0px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
}

.table-content-container .vessel-detail-main-container {
  display: flex;
  flex: 1;
  gap: 0.5em;
  align-items: stretch;
}

.table-content-container .vessel-detail-main-container .route-action-container {
  display: flex;
  align-items: stretch;
  gap: 0.5em;
  width: 54%;
}

.table-content-container .vessel-detail-main-container .route-action-container .vessel-detail-container .form-switch .form-check-input:checked,
.form-switch .form-check-input:checked {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
}

.table-content-container .vessel-detail-main-container .route-action-container .vessel-detail-container .form-switch .form-check-input,
.form-switch .form-check-input {
  border-color: var(--sub_text);
  background-color: var(--white);
}

.table-content-container .vessel-detail-main-container .route-action-container .vessel-detail-container .form-switch .form-check-input:focus {
  box-shadow: none !important;
}
.custom-radio {
  display: flex;
  gap: 0.3em;
  flex-wrap: wrap;
}

.custom-radio [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

[type="radio"]+.label-icon {
  cursor: pointer;
  border: 1px solid var(--primary_color);
  padding: 0.4em 0.7em;
  border-radius: 50%;
  margin-right: auto;
}

[type="radio"]:checked+.label-icon {
  background: #c98dbc;
  color: black;
}

/* legislative scenario */

.legislativ-scenario-container,
.price-scenario-container {
  position: relative;
}

.legislativ-scenario-container .title,
.price-scenario-container .title {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-bottom: 0.5em;
}

.price-scenario-container .title h1 {
  color: var(--black);
  font-size: 3em;
}

.legislativ-scenario-container .scenario-container {
  background-color: var(--table_bg);
  border-radius: 36px;
  padding: 2em;
  display: flex;
  align-items: center;
  gap: 1em;
  margin-top: 3em;
}

.legislativ-scenario-container .select-scenario .select-box-container {
  width: 18.75em;
}
.legislativ-scenario-container .scenario-container span {
  width: 5%;
  text-align: center;
  margin-bottom: 0;
  font-weight: bold;
  margin-top: 1em;
}

.legislativ-scenario-container .scenario-container .scenario-add-container {
  width: 30%;
  display: flex;
  align-items: center;
  gap: 1em;
}

.legislativ-scenario-container .scenario-container .scenario-add-container .input-container {
  width: 90%;
}

.legislativ-scenario-container .scenario-container .scenario-add-container .add-btn {
  width: 5%;
  margin-top: 1em;
}

.legislative-main-container {
  position: relative;
}

.legislative-main-container .nav-tabs {
  border: none !important;
  overflow: visible;
}

.legislative-main-container .nav-link {
  color: var(--primary_color);
  border: 1px solid var(--primary_color);
  padding: 0.656em 1em;
}

.legislative-main-container .nav-link:hover {
  color: var(--primary_color);
  border: 1px solid var(--primary_color);
}

.legislative-main-container .nav-tabs .nav-link {
  border-radius: 50px;
}
.legislative-main-container .nav-tabs .nav-item {
  margin-right: 0.5em;
}

.legislative-main-container .nav-tabs .nav-link.active {
  color: var(--white);
  background-color: var(--primary_color);
  border: 1px solid var(--primary_color);
}

.legislative-main-container .actions .btn {
  padding-top: 0.525em;
  padding-bottom: 0.525em;
}

.imo-container .carbon-input-container,
.imo-chart-container,
.eu-content-container {
  display: flex;
  align-items: center;
  gap: 1em;
}
.eu-content-container {
  align-items: stretch;
}
.imo-container .carbon-container {
  background-color: var(--white);
  padding: 1em;
  border: 1px solid #ADADAD;
}
.imo-container .carbon-input-container .levy-amount-container {
  width: 16.1em;
}

.levy-amount-container input {
  padding-right: 7.8rem !important;
}

.imo-chart-container .cii-chart,
.imo-chart-container .intensity-limit,
.eu-content-container .eu-ets-container,
.eu-content-container .eu-fm-container {
  background-color: var(--white);
  padding: 1.5em 1em;
  width: 50%;
  border-radius: 36px;
}

.imo-container .carbon-container .title p,
.imo-chart-container .cii-chart .title p,
.imo-chart-container .intensity-limit .title p,
.eu-content-container .eu-ets-container .title p,
.eu-content-container .eu-fm-container .title p {
  color: var(--primary_color);
  font-size: 1.5em;
}

.imo-chart-container .intensity-limit .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.border-1 {
  border: 1px solid #ADADAD;
}
.eu-content-container .eu-ets-container .title p {
  width: 3.5em;
}
.imo-chart-container .cii-chart .z-factor-input .mail-input {
  width: 8em;
}

.imo-chart-container .cii-chart .z-factor-input .mail-input input,
.imo-chart-container .intensity-limit .penlty-input input {
  width: 100%;
  border: 1px solid #ADADAD;
  padding: 0.6em 1.13em;
  border-radius: 50px;
}


.error {
  color: red;
  font-size: 0.7em;
  margin-top: 0.5em;
  height: 1em;
}
.h-60 {
  height: 60px;
}
.main-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.main-container .login-image {
  padding: 1em 3em;
  position: relative;
  display: block;
  width: 30%;
  height: auto;
  overflow: hidden;
}

.main-container .login-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-container .main-container-actions {
  position: relative;
  display: flex;
  width: 60%;
  gap: 1em;
}

.main-container .main-container-actions button {
  display: flex;
  align-items: center;
  gap: 0.3em;
  padding: 0.5em 1em;
  width: auto;
  justify-content: center;
}

.main-container .main-container-actions button:hover,
.main-container .main-container-actions button:focus,
.main-container .main-container-actions button:active {
  background-color: var(--primary_color) !important;
  color: var(--white) !important;
}

.eu-content-container .input-container .first-container {
  display: flex;
  align-items: center;
  gap: 1em;
}
.eu-content-container .input-container .first-container .cost-of-compliance .input-label {
  width: 7.7em;
}
.eu-content-container .input-container .first-container .cost-of-compliance input{
  width: 12.6em;
}

.eu-content-container .input-container .growth-pa input {
  max-width: 7em;
}

.eu-content-container .input-container .growth-pa .input-label {
  width: 4.8em;
}


.tab-content .actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5em;
  margin-top: 2em;
}


.price-scenario-container .price-scenario-tab-container .tab-data {
  display: flex;
  align-items: self-start;
}

.price-scenario-container .price-scenario-tab-container .nav {
  padding-left: 45px;
  width: 15%;
}

.price-scenario-container .price-scenario-tab-container .tab-content {
  padding: 0;
  width: 85%;
}

.price-scenario-tab-container .nav-link.active,
.price-scenario-tab-container .nav-link:focus {
  box-shadow: unset;
  outline: none;
  background-color: unset;
  border: none;
  color: var(--primary_color);
}
.price-scenario-tab-container .nav-link {
  position: relative;
  display: block;
  padding: 0 0 63px;
  border-radius: unset;
  background-color: transparent;
  border: none;
  margin: 0;
  width: 11em;
}
.price-scenario-tab-container .nav-link span {
  position: relative;
  display: block;
  padding: 20px 29px 19px 30px;
  border: 1px solid var(--primary_color);
  border-radius: 50px;
  background-color: var(--listContainer);
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: var(--primary_color);
}
.price-scenario-tab-container .nav-link:before {
  content: "";
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  background-color: var(--listContainer);
  border-radius: 50%;
  left: -40px;
  top: 20px;
}
.price-scenario-tab-container .nav-link.active:before {
  background-color: var(--primary_color);
}
.price-scenario-tab-container .nav-link:after {
  content: "";
  position: absolute;
  display: block;
  width: 2px;
  height: 85%;
  background-color: var(--listContainer);
  left: -30px;
  top: 44px;
}
.price-scenario-tab-container .nav-link:last-child::after {
  display: none;
}
.price-scenario-tab-container .nav-link.fill span {
  background-color: var(--green);
  color: #fff;
  border: 1px solid var(--green);
}
.price-scenario-tab-container .nav-link.fill span {
  position: relative;
  display: block;
}
.price-scenario-tab-container .nav-link.fill:before {
  background: var(--green) url("../assets/check.png");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
}

.price-scenario-container .price-scenario-tab-container .tab-content .tab-pane .actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}

.add-fuel-container .fuel-container-inputs {
  margin-top: 1em;
  background-color: var(--table_bg);
  padding: 2em;
  border-radius: 36px;
  display: flex;
  align-items: center;
}

.add-fuel-container .fuel-template-container {
  margin-top: 1em;
  background-color: var(--table_bg);
  padding: 2em;
  border-radius: 36px;
}

.add-fuel-container .fuel-template-container .fuel-template {
  display: flex;
  align-items: center;
  gap: 1em;
}

.add-fuel-container .fuel-template-container .fuel-template .right-container {
  width: 50%;
  height: 200px;
  overflow-y: auto;
}
.add-fuel-container .fuel-template-container .fuel-template .left-container{
  width: 50%;
  height: 200px;
}

.add-fuel-container .fuel-template-container .left-container {
  border-right: 1px solid var(--sub_text);
  padding-right: 1em;
}

.add-fuel-container .fuel-template-container .first-select-container,
.add-fuel-container .fuel-template-container .second-input-container {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-bottom: 1em;
}

.add-fuel-container .fuel-template-container .first-select-container .fuel-family-container,
.add-fuel-container .fuel-template-container .first-select-container .fuel-origin-container,
.add-fuel-container .fuel-template-container .second-input-container .base-year-input,
.add-fuel-container .fuel-template-container .second-input-container .inflation-rate-input {
  width: 50%;
}

.base-year-input input {
  position: relative;
}

.base-year-input .select-box-container {
  position: absolute;
  width: 45%;
  top: 5px;
  right: 6px;
  z-index: 1;
}

.base-year-input .select-box-container .select-box {
  padding: 0.4em 1em;
}

.base-year-input .select-box-container .select-box .select-icon {
  top: 47%;
}

.base-year-input .select-box-container .dropdown-menu-list .dropdown-container .dropdown-item {
  color: var(--black);
}

.price-deltas-container .title p {
  color: var(--primary_color);
  font-size: 1.5em;
}

.price-deltas-container .price-selection-container {
  display: flex;
  align-items: start;
  gap: 1em;
}

.price-deltas-container .price-selection-container .select-basin-container {
  width: 50%;
}

.price-deltas-container .price-selection-container .basin-value {
  width: 49%;
}

.selected-basin-data {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-bottom: 0.7em;
}

.selected-basin-data .basin-name,
.selected-basin-data .price {
  background-color: var(--white);
  padding: 0.6em 1.13em;
  border-radius: 36px;
  display: flex;
  align-items: center;
  position: relative;
  width: 50%;
  border: 0.094em solid #ADADAD;
}

.selected-basin-data .price {
  justify-content: space-between;
}

.selected-basin-data .price input {
  border: none;
  box-shadow: none;
}

.selected-basin-data .price input:focus {
  outline: none;
  border: none;
}

.selected-basin-data .price img {
  margin-top: 0.3em;
}

.selected-basin-data .basin-name p,
.selected-basin-data .price p {
  margin-bottom: 0;
  color: var(--primary_color);
}

.price .unit-icon {
  position: absolute;
  right: 10px;
  pointer-events: none;
}

.fuel-template-container .add-template-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2em;
}

.refine-price-container {
  border-radius: 36px;
  padding: 2em;
}

.refine-price-container .refine-price-tab-header {
  display: flex;
  align-items: center;
  gap: 0.8em;
}

.refine-price-container .refine-price-tab-header .price-scenario,
.refine-price-container .refine-price-tab-header .basin-list,
.refine-price-container .refine-price-tab-header .fuel-family,
.refine-price-container .refine-price-tab-header .action-container {
  width: 25%;
}

.refine-price-container .refine-price-tab-header .action-container .priceby,
.refine-price-container .refine-price-tab-header .action-container .fuel-origin {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-left: 1em;
  justify-content: space-between;
}

.refine-price-container .refine-price-tab-header .action-container .priceby-toggle{
  display: flex;
  align-items: center;
  text-align: right;
  gap: 1.25em;
  justify-content: flex-start;
}

.refine-price-container .refine-price-tab-header .action-container .priceby .form-check-input:checked {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
}

.form-check-input:focus {
  box-shadow: none !important;
}

.refine-price-chart-container {
  position: relative;
  margin-top: 1em;
}

.refine-price-chart-container p {
  color: var(--black);
  font-weight: 700;
}

.refine-price-chart-container .chart-container {
  background-color: #F4FBFF;
  margin-top: 1em;
  border-radius: 36px;
  padding: 1em;
  height: 30em;
}

.chart-label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 2%;
}

.chart-label.eu-ets {
  z-index: 1;
}

.chart-label p {
  margin-bottom: 0;
  background-color: var(--labelBg);
  padding: 0.5em 1em;
  border-radius: 8px;
  color: var(--green);
  font-size: 0.8em;
}

.chart-information-contain {
  background-color: var(--table_bg);
  margin-top: 1em;
  padding: 1em;
  border-radius: 20px;
  position: relative;
}

.chart-bottom-title {
  position: absolute;
  bottom: 5%;
}

.chart-bottom-title p {
  margin-bottom: 0;
  font-size: 0.8em;
  color: var(--black);
  opacity: 0.7;
}

.pdf-container .tab-content-title {
  justify-content: left;
}
.pdf-container .tab-content-title h2 {
  font-size: 2em !important;
}
.pdf-container .tab-content-title h2>span {
  font-size: 1em !important;
}


.pdf-container {
  padding: 5em 8em;
  position: absolute;
  width: 100%;
  top: -11000%;
}

.pdf-container .fleet-chart-container {
  background-color: transparent;
  height: 650px;
}

.pdf-container [id^="legend-container"] {
  background-color: transparent !important;
}

.text-info-modal ul li {
  margin-top: 1em;
  font-size: 1em;
  line-height: 25px;
}

.color-box {
  width: 40px;
  height: 15px;
}

.legend-data {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--input_bg);
  padding: 1em;
  border-radius: 0px 0px 20px 20px;
}

#legend-container span {
  cursor: pointer;
}

.premium-icon {
  width: .8rem;
}

.common-input,
.common-input-v2 {
  position: relative;
}
.common-input-v2 input {
  width: 100%;
  border: 1px solid #ADADAD;
  padding: 0.6em 1.13em;
  border-radius: 50px;
}

.common-input input {
  width: 100%;
  background-color: var(--input_bg);
  border: 1px solid transparent;
  padding: 1em;
  border-radius: 50px;
}

.common-input-v2 input::placeholder {
  font-size: 0.8em;
  color: var(--sub_text);
  font-weight: 500;
}
.common-input-v2 span {
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
  font-size: 0.9em;
  color: var(--primary_color);
  z-index: 1;
}

.common-input span {
  position: absolute;
  top: 50%;
  right: 1.5em;
  transform: translateY(-50%);
  font-size: 0.8em;
  color: var(--primary_color);
  z-index: 1;
}

.error-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.cross-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.cross-icon-svg {
  width: 64px;
  height: 64px;
}

.gap {
  display: flex;
  width: 491px;
  padding: 36px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.contact-us {
  display: flex;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.close-popup {
  width: "24px";
  height: "24px";
}

.close-popup:hover {
  background-color: #a7a4a4;
  color: #fff;
}

.editor-menu-list-items {
  color: var(--z-joule-com-shuttle-gray, #565C68);
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 0.625rem;
}

.editor-menu-window {
  padding: 1.5em;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
  align-self: stretch;
}

.settings-menu-window {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding-left: 0 !important;
  padding-top: .5rem !important;
  padding-bottom: .5rem !important
}

.settings-menu-window li {
  padding: 0.4rem 1rem !important;
}

.settings-menu-window li:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.user-info {
  display: flex;
  padding-bottom: 0.75em;
  padding-top: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75em;
  align-self: stretch,
}

.hamburger-dropdown {
  display: inline-flex;
  padding-top: 0px;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  z-index: 1;
  background: white;
  right: 0rem;
  border-radius: .5rem;
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
}

.selector-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  margin-right: 1em;
  margin-top: 1rem;
}


.selector-names {
  color: #282828;
  font-size: 14.4px;
  font-style: normal;
  font-weight: 400;
}

.selector-names-year {
  color: #282828;
  font-size: 14.4px;
  font-style: normal;
  font-weight: 400;
  margin-top: 0.125rem;
}

.menu-avatar {
  width: 3.75em;
  height: 3.75em;
  flex-shrink: 0;
  border-radius: 2.5em;
  border: 2px solid var(--z-joule-com-nero-nero, #FFF);
  background: var(--sfsf-z-joule-com-lochmara, #0284C4);
}

.custom-user-info {
  color: var(--z-joule-com-shuttle-gray, #565C68);
  font-family: Cabin, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 16rem;
  overflow: hidden;
  padding: 0 1rem;
  text-align: center;
  text-overflow: ellipsis;
}

.optimize-button {
  display: flex;
  padding: 0.656em 1em;
  justify-content: center;
  align-items: center;
  gap: 0.625em;
  border-radius: 100px;
  background: #2C8EC6;
  margin-top: 1.75em;
  color: #FFF;
  font-family: Cabin, sans-serif;
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  width: max-content;
  border: none;
}

.selector-actions-container {
  position: relative;
  display: flex;
  width: 100%;
}

.select-route {
  color: #282828;
  font-family: Cabin, sans-serif;
  font-size: 1.125em;
  font-style: normal;
  font-weight: 600;
  text-align: left;
}

.selected-route {
  color: #2C8EC6;
  font-family: Cabin, sans-serif;
  font-size: 1.125em;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px;
}

.assign-route-btn {
  display: flex;
  padding: .5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625em;
  flex: 1 0 0;
  border-radius: 100px;
  border: 1px solid #2C8EC6;
  color: #FFF;
  font-family: Cabin, sans-serif;
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
}

.tooltip-inner {
  border-radius: 4px;
  background-color: rgba(97, 97, 97, 0.92) !important;
  color: rgb(255, 255, 255);
  font-size: 0.6rem;
  padding: .3rem;
  max-width: 18.75rem;
  overflow-wrap: break-word;
  font-weight: 500;
  position: relative;
}

.tooltip-arrow::before {
  border-top-color: rgba(97, 97, 97, 0.92) !important;
}

.hover-element:hover {
  text-decoration: underline;
  cursor: pointer;
}

.ft-1rem {
  font-size: 1rem !important
}

.small-text {
  font-size: .8rem !important;
}


.plus-btn {
  padding: 0;
  cursor: pointer;
  outline: none;
  border: none;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border-bottom: 2px solid #ccc;
}

.plus-icon {
  font-size: 1em;
  margin-right: 0.2em;
  margin-top: 0.25em;
}

.dropdown-add-btn {
  border: none;
  outline: none;
  font-size: 40px;
  background: none;
  text-align: right;
}

.input-box {
  padding: 0.8em 2em;
  align-items: center;
  border-radius: 1.375em;
  border: 0.094em solid #ADADAD;
  background: #FFF;
  margin: 0.5em;
}

.input-box-drop-down {
  padding: 0.8em 1em;
  align-items: center;
  border-radius: 1.375em;
  border: 0.094em solid #ADADAD;
  background: #FFF;
}

.selected-basins{
  min-height: 1em;
  max-height: 100px;
  margin-top: 1em;
}

.selected-basins.with-border {
  border-radius: 0.5em;
  border: 0.094em transparent #ADADAD;
}

.no-highlight:focus {
  outline: none;
  box-shadow: none;
  --bs-bg-opacity: 1;
}
.chart-bg.emission-intencsity canvas {
  padding-top: 2em;
}

.custom-select-box {
  width: 250px;
}

.btn-secondary-add{
  background-color: #212529;
}

.form-check-label.stretch {
  width: 6rem;
}

.custom-mr-2 {
  margin-right: 0.5rem;
}

.d-flex.align-items-center.mb-4 {
  margin-left: 0.5rem;
}

.refine-margin {
  margin-right: 0.5rem;
}

.fixed-switch {
  position: absolute;
  left: 10px;
}

.input-container {
  position: relative;
}

.currency-symbol {
  position: absolute;
  left: 0.8em;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 0.8em;
}

.partial-year{
  display: flex;
  margin-top: 4em;
}

.emissions-allign{
  text-align: left;
}

.custom-row>* {
  width: auto !important;
  max-width: none !important;
  flex-wrap: wrap;
  flex: 0 0 auto;
}

.custom-key {
  margin: 0;
  word-wrap: break-word;
  text-align: left;
  font-size: 1em;
}

.custom-value {
  flex: 1;
  margin: 0;
  word-wrap: break-word;
  text-align: left;
}

.icon-border {
  border: 1px solid;
  border-radius: 3px;
  padding: 5px;
  border-color: #8893A4;
}

.col-auto{
  flex: 0 0 auto;
}

.loader-display{
  display: inline-block;
}

.revenue-border{
  border-left: 1px solid;
}
.html-legend-group{
  height:2.5rem;
  margin-right: .5rem;
}

.pdf-export .legend-data {
  background-color: #ffffff !important;
}

/* Toggle colors==============================================*/
/* Base toggle style */
.form-check-input.no-dim-toggle {
  /* Remove default input look in various browsers */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Ensure no default margins/padding from Bootstrap */
  margin: 0 !important;
  padding: 0 !important;

  /* Size and shape of the "track" portion */
  width: 40px;
  height: 20px;
  border: 1px solid #0d6efd !important;  /* Blue border */
  border-radius: 20px;                   /* Rounded corners */
  background-color: #ffffff !important;  /* White background */

  /* Position so we can absolutely position the knob within */
  position: relative;
  cursor: pointer;

  /* Optional transitions for toggling background, if desired */
  transition: background-color 0.2s ease-in-out;
}

/* Keep white track and blue border when checked, if needed */
.form-check-input.no-dim-toggle:checked {
  background-color: #ffffff !important;
  border-color: #0d6efd !important;
}

/* Create the knob (thumb) */
.form-check-input.no-dim-toggle::after {
  content: "";
  display: block;
  position: absolute;

  /* Knob size smaller than track */
  width: 16px;
  height: 16px;
  border-radius: 50%;

  /* Center it vertically, start near the left */
  top: 50%;
  left: 2px;
  transform: translateY(-50%);

  /* Knob color */
  background-color: #0d6efd !important;

  /* Smooth movement */
  transition: left 0.2s ease-in-out;
}

/* Slide the knob fully right when checked */
.form-check-input.no-dim-toggle:checked::after {
  left: calc(100% - 2px - 16px);
  /* Explanation of calc():
     100% = total track width (40px)
     - 2px = right-side gap
     - 16px = knob width
     So the knob is flush on the right side. */
}



/* Fuel Grid==============================================*/


/****** 2. Create a light grid ******/
.legend-grid {
  /* We already set display: grid in JS, so you can add additional styles here */
  border: 1px solid #eee; /* Light outer border if you like */
}

.legend-cell {
  /* Add borders on each cell to form horizontal & vertical lines */
  border: 1px solid #eee; /* Very light borders (horizontal + vertical) */
  padding: 4px;           /* Spacing inside each cell if needed */
}

/****** 3. Optional styling for the legend items ******/
.legend-item {
  margin-bottom: 2px; /* Small spacing between items in the same cell */
}
